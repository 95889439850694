<template>
    <v-container fluid fill-height class="error404">
        <img style="width: 100%; height: auto;" src="../../assets/images/404.svg" alt="404 not found"/>
    </v-container>
</template>

<script>
export default {
    name: "Error404",
    data() {
        return {
            timeout: null
        }
    },
    mounted() {
        this.timeout = setTimeout(() => {
            this.$router.push({name: "Home"});
        }, 5000);
    },
    beforeDestroy() {
        if (this.timeout)
            clearTimeout(this.timeout);
    }
}
</script>
